body,
html {
	background-color: #f1f4ff;
	font-family: 'Montserrat', sans-serif;
	color: #010036;
	min-height: 100vh;
}

.btn {
	cursor: pointer;
	align-items: center;
	height: 48px;
	display: inline-flex;
	justify-content: center;
	color: #010036;
	font-weight: bold;
	text-align: center;
	user-select: none;
	background-color: transparent;
	border: 3px solid transparent;
	padding: 0 2rem;
	border-radius: 4px;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	text-transform: none;
	align-items: center;
	text-decoration: none;
	background-color: #fed633;
	border-color: #010036;
}

.btn:hover:not(:disabled) {
	background-color: #010036;
	color: #fff;
}

.btn:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
